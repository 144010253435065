import React from 'react';
import Layout from '../components/layout/Layout';

export default function Top() {
  return (
    <Layout>
      <div className="w-full flex items-center justify-center h-64 font-bold">
        <div className="text-3xl">
          Page not found
        </div>
      </div>
    </Layout>
  );
}
